.footer-section {
  padding: 60px 190px 60px 190px;
}

@media only screen and (max-width : 767px) {
  .footer-section {
    padding: 60px 10px 60px 15px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footer-section {
    padding: 60px 16px 60px 16px;
  }
}

@media (min-width: 1024px) and (max-width: 1286px) {
  .footer-section {
    padding: 60px 20px 60px 20px;
  }
}

.Footer {
  border-style: solid;
  border-width: 01px 0px 0px 0px;
  border-color: #0CBCD9;
  padding: 80px 0px 80px 0px; 
}

.font-roboto-condensed {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.font {
  text-align: justify;
  font-family: "Montserrat Alternates", Sans-serif;
  font-weight: 600;
  color: black;
}

#padding-footer {
  border-style: solid;
  border-width: 01px 0px 0px 0px;
  border-color: #0CBCD9;
  padding: 80px 0px 80px 0px;
}


::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #ededed;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #645a5ab8;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
    cursor: pointer;
  }

h6{
    font-weight: 600;
    font-style: normal;
    letter-spacing: 2px;
    color: #797979;
}

span{
    font-family: "Manrope", Sans-serif;
    font-size: 12px;
    line-height: 16px;
}

h2{
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 24px;
    font-weight: 600;
}

p{
    text-align: justify;
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 14px;
    /* font-weight: 600; */

}
a{
    color: #000000;
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 14px;
    font-weight: 600;
}

h5{
    font-family: "Montserrat Alternates", Sans-serif;
    font-weight: 600;
}

h3{
    font-family: 'Cabin';
    color: #81898d;
    font-size: 22px;
    text-shadow: none;
    line-height: 1.6;
    font-weight: normal;
    font-style: italic;
    text-decoration: none;
    text-align: inherit;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    font-weight: 500;
}

@media only screen and (max-width : 767px) {
  .font-mv {
    font-size: 1.5rem !important;
    text-align: center !important;
  }
}

@media only screen and (max-width : 767px) {
  .font-mv-h6 {
    text-align: center;
  }
}
@media only screen and (max-width : 767px) {
  .font-mv-slider {
    font-size: 1rem;
    width:80% !important
  }
}
@media only screen and (max-width : 767px) {
  .font-mv-enroll {
    text-align: left;
  }
}
@media only screen and (max-width : 767px) {
  .dropdown-menu {
    text-align: left !important;
  }
}
@media only screen and (max-width : 767px) {
  .dropdown-menu-1 {
    text-align: left !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

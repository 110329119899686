#box-shodow {
  box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5) inset;
  /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 20px 20px 20px 20px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 10px 10px 10px 10px; */
}

#bg-image-Cards{
  background: url(./Image/day-care-hero-blobs-background\ \(1\).svg);
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  background-size: cover;
  height: 100%;
  width: 100%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 104px 40px 104px 40px;
}

#padding-Form{
  padding: 100px 40px 40px 40px;
}
.mb-1{
    color: #00beda;
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -1px;
}

.border{
    border:1px solid #00beda;
    border-radius: 5px;
}

#message{
    padding-bottom:8rem ;
}

@media only screen and (max-width : 767px) {
    #Form-padding{
        padding: 0px 40px 0px 40px;
        background-color: #00beda
    }
  }
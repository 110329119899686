.tab-img{
    width: 27rem;
    height: fit-content;
    height: 16rem;
    object-fit: cover;
}
.ytp-cued-thumbnail-overlay-image{
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 100%; */
    position: absolute;
}
.view-btn{
    color: #33c2fc;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #33c2fc;
}

.allvideo-section{
    padding: 80px 0 20px 0;
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom:56.25%;
    /* Aspect ratio 16:10 (height/width * 100) */
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.verdana-font{
    font-family: Roboto, Arial, Helvetica, Verdana, sans-serif
}
.video-thumbnail{
    width: 75px;
    height: 42px;
    margin: auto 0;
}
.play-icon{
    color: white;
    margin-left: -15px;
    text-shadow: 0 0 13px #939393;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
  }

  
  .img-overlay{
    position: absolute;
  }
  .image-container:hover  .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 14rem;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    pointer-events: none;
    opacity: 1;
}
.image-container:hover  {
  cursor: pointer;
}
.image-container:hover .middle {
    opacity: 1;
  }

.styles-module_navigation__1pqAE {
    font-size: 25px !important;
    opacity: 2 !important;
}



.styles-module_wrapper__1I_qj {
    z-index: 3 !important;
}

.slider-text-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    z-index: 2;
    opacity: 0;
  }
  .show{
    opacity: 1;
    animation: slideUp 2s ease-in-out forwards;
  }
  
  @keyframes slideUp {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }
 .slider-text{
    font-family: "Roboto Condensed", sans-serif !important;
    font-weight: 600;
    font-size: 35px;
 }
 .slide-img{
    object-position: center;
    object-fit: cover;
    height: 460px;
    width: 100%;
 }

@media screen and (max-width: 768px) {
    .tab-img{
        width: 100%;
    }
    .allvideo-section{
        padding: 20px 20px;
    }
    .slide-img{
        height: 460px;
        object-fit: cover;
    }
    .slider-text{
        font-size: 20px;
    }
}

@media (min-width: 640px){
.sm\:max-w-sm {
    max-width: 27rem;
}
}
.mb-3{
    color: #00beda;
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1px;
}


#bg-image-gallery{
    background: url(./INTERIOR/day-care-hero-blobs-background\ \(1\).svg);
    background-repeat: no-repeat;
    background-size: 40%;
    background-position-x: right;
}
.Font-Family{
    font-family: "Montserrat Alternates", Sans-serif !important;
    font-size: 24px !important;
    line-height: 1em !important;
    color: #000000 !important;
    font-weight: 600;
}

.font-letter{
    color: #000000 !important;
    font-family: "Montserrat Alternates", Sans-serif !important;
    font-size: 18px !important;
    line-height: 1.8em !important;

}

.font-letter-p{
    color: #000000 !important;
    font-family: "Montserrat Alternates", Sans-serif !important;
    font-size: 14px !important;
    line-height: 1.8em !important;
    font-weight: 600 !important;
}

#padding-Civil{
    padding: 80px 40px 80px 40px;
}
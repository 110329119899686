.active-line {
    height: 3px;
    width: 100%;
    left: 0;
    background-color: #33C2FC;
    z-index: 2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 460px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    pointer-events: none;
}


.splide__arrow svg {
    fill: rgb(82, 78, 78) !important;
}

.splide__arrow {
    background: transparent !important;

}

@media screen and (max-width: 768px) {
 
    
}
#button{
    font-family: "Manrope", Sans-serif;
    font-size: 12px;
    line-height: 16px;
    fill: #0CBCD9;
    color: #0CBCD9;
    background-color: #F4F4F4;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: #0CBCD9;
    border-radius: 6px 6px 6px 6px;
    padding: 8px 14px 8px 14px;
}

#text-font{
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 0px;
    letter-spacing: -0.2px;
    word-spacing: 0px;
}

.border-bottom{
    border-radius: 0px 0px 0px 0px;
    border-style: solid;
    border-width: 0px 0px 02px 0px;
    border-color: #0CBCD9;
    box-shadow: 0px 0px 10px 0px rgba(65.08532608695653, 132.19255832449628, 143.25, 0.48);
}
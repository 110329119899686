/* Skill development css */

.mb-1 {
    color: #00beda;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -1px;
  }
#padding-Development{
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 160px 40px 80px 40px; 
}
/* #padding-Content{
    padding: 104px 40px 104px 40px;
    width:80%
} */


#bg-image{
    background: url(../Home/Image/day-care-hero-blobs-background\ \(1\).svg);
    background-repeat: no-repeat;
    background-size: 40%;
    background-position-x: right;
   
}

.content-padding{
    padding: 30px 40px 104px 40px;
}

.font-family-ktrc{
    font-family: "Montserrat Alternates", Sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4em;
    letter-spacing: -0.4px;
}
/* Parallex Animation css */
.swiper {
  width: 100%;
  height: 65vh;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  background-attachment: fixed;
}
.Font-style{
  background: RGBA(255, 255, 255, 0.8);
  opacity: 1;
  padding: 0.5em 1.5em 0.5em 1.5em;
  box-shadow: none;
  border: 0px solid RGBA(0, 0, 0, 1);
  border-radius: 99px;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
}
.Font-style-h1{
  font-family: "Montserrat Alternates", Sans-serif;
  color: RGBA(0, 0, 0, 0.8) !important;
  font-size: 2rem;
  text-shadow: none !important;
  line-height: 1 !important;
  font-weight: 600 !important;
  font-style: normal !important;
  text-decoration: none !important;
  letter-spacing: normal !important;
  word-spacing: normal !important;
  text-transform: none !important;
}

@media only screen and (max-width : 767px) {
  .Font-style-h1 {
    font-size: 2rem !important;
  }
}

.bg-image-slider{
  background: url(./Image/a\ \(1\).jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.bg-image-slider-1{
  background: url(./Image/b.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.bg-image-slider-2{
  background: url(./Image/civi-3\ \(1\).jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.bg-image-slider-3{
  background: url(./Image/c-3\ \(1\).jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}


/* end */



/* Placement assitan css start */

.cards {
  box-shadow: 0px 0px 6px 0px rgba(112, 214.00000000000009, 226, 0.81);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 10px 10px 10px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  width: 23rem;
}
.transition {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 5px 5px 5px 5px;
}

.text-color {
  color: #000;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 10px;
  font-weight: 100;
}

/* end */

/* Top Softwares css */

.elementor-animation-grow:hover {
  transform: scale(1.1);
}

.image:hover {
  transform: scale(1.1);
}

#Software {
  background: url(./Image/day-care-hero-blobs-background\ \(1\).svg);
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: top right;
  height: 100%;
  width: 100%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 104px 40px 104px 40px;
}

#top-software {
  /* display: flex;
  justify-content: space-evenly; */
  padding: 12px 16px 12px 16px;
  width: 90%;
  align-items: center;
}

.clm-gap- {
  column-gap: 4rem;
}

.mb-10 {
  color: #00BEDA;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -1px;
}
/* end */

/* KTRCSkill css */
#Padding-skill{
  padding: 10px 40px 20px 40px;
}

.justify {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
}

.text-color {
  color: #00beda;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 40px;
  font-weight: 600;
}

.text-base {
  color: black;
  font-size: 14px;
}

/* end */

/* Cources css */

#bg-image-Cources {
  background: url(./Image/69-scaled.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  width: 100%;
  padding: 104px 40px 104px 40px;
}

#bg-image-1 {
  background: url(./Image/Firefly-civil-engineer-91122-1.jpg);
  background-size: cover;
  background-image: none;
  background-color: white;
}
.background-overlay{
width: 100%;
height: 100%;
top:0;
left: 0;
position: absolute;
opacity: 0.23;
background-size: cover;
background-position: center;
}

.bg-1:hover {
  background-image: url("./Image/Firefly-civil-engineer-91122-1.jpg");
  opacity: 0.23;
}

.bg-2:hover {
  background-image: url("./Image/Firefly-Interior-Architecture-Design-91122.jpg");
  opacity: 0.23;
}

.bg-3:hover {
  background-image: url("./Image/Firefly-Mechanical-Design-20759.jpg");
  opacity: 0.23;
  
}
.bg-4:hover {
  background-image: url("./Image/Firefly-Animation-97619.jpg");
  opacity: 0.23;
}

.bg-5:hover {
  background-image: url("./Image//Firefly-Personality-Development-24913.jpg");
  opacity: 0.23;
}

.bg-6:hover {
  background-image: url("./Image/Firefly-Graphic-Design-71296.jpg");
  opacity: 0.23;
}

/* end */

/* CareerOppurtunities css */

#padding {
  padding: 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #00beda;
  border-radius: 12px 12px 12px 12px;
  text-align: center;
}

#padding-1 {
  padding: 100px 0px 40px 0px;
}

/* end */

/* Empowring css */


.h1{
  color: #D8D8D8;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -1px;
}
.p{
  color: #D8D8D8;
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 20px;
  font-weight: 200;
}
.button{
  fill: #F8F8F8;
  color: #F8F8F8;
  background-color: #0CBCD9;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #0CBCD9;
  border-radius: 5px 5px 5px 5px;
  padding: 12px 16px 12px 16px;
}
#padding-Empowring{
padding: 80px 40px 80px 40px;
}

.Font-Family-Text{
  font-family: "Montserrat Alternates", Sans-serif;
  font-size: 18px;
}




 @media screen and (min-width: 110%) {
  #top-software {
    width: 100%; /* Change width to 100% */
  }
}